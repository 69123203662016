import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from '../components/organisms/seo';
import Layout from '../components/organisms/layout';
import Cursor from '../components/atoms/cursor';
import { media } from '../utils/media-queries';
import Richtext from '../components/atoms/richtext';

const LegalPage = ({ data }) => {
  const { title, _rawContent } = data.legalNotice.nodes[0];

  return (
    <Layout>
      <SEO siteTitle="Disclaimer" />
      <Cursor />
      <StyledLegal>
        <div className="grid spacing-inner content">
          <h2>{title}</h2>
          <div className="copy-small">
            <Richtext blocks={_rawContent} />
          </div>
        </div>
      </StyledLegal>
    </Layout>
  );
};

const StyledLegal = styled.section`
  padding-top: calc(var(--spacing-XXL) * 1.5);
  padding-bottom: calc(var(--spacing-XXL) * 1.5);

  h2 {
    grid-column: 1 / 3;
    color: black;
  }

  .copy-small {
    grid-column: 1 / 10;
    p {
      padding-bottom: var(--v-spacing-S);
    }
  }

  @media ${media.L} {
    padding-bottom: 0;

    h2 {
      grid-column: 1 / 3;
    }

    .copy-small {
      grid-column: 3 / 6;
    }
  }
`;

export const query = graphql`
  query LegalNotice {
    legalNotice: allSanityLegalNotice {
      nodes {
        title
        _rawContent
      }
    }
  }
`;

LegalPage.propTypes = {
  data: PropTypes.object,
};

export default LegalPage;
